
.input{
    width: 100%;
    height: 30px;
    padding: 10px 0;
    border: none;
    border-bottom: 2px solid #7912f7;
    background-color: transparent;
    color: #7912f7;
    font-size: large;
}
.input:focus{
    outline: none;
}
.color{
    color: #7912f7;
}
.TestimonialCardInput{
    height: 100%;
    border: none;
}
.TestimonialCardInput:focus{
    outline: none;
}