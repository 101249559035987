.BlogsCard{
    width: 23%;
    display: none;
}  
@media screen and ( max-width:900px ){
    .BlogsCard{
        width: 100%;
        display: flex;
    }  
}
@media screen and ( max-width:600px ){
    .BlogsCard{
        width: 100%;
        display: flex;
    }  
}
@media screen and ( max-width:600px ){
    .BlogsCard{
        width: 100%;
        display: flex;
    }  
}





/* width={["100%", "47.5%", "32%", "23%"]} */